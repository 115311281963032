// CrayonInput.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Send, Plus } from 'lucide-react';
import Logo from './elements/Logo.js'
import { getClarifyingQuestions, getStructuredPromptFromLLM, regeneratePromptFromFeedback } from './openaiApi';
import Spinner from './elements/Spinner.js';
import { diffWordsWithSpace } from 'diff';
import { generateClient } from 'aws-amplify/data'

const client = generateClient({
  authMode: 'apiKey',
});

const createPromptLog = async (prompt, response, openaiRequest, openaiResponse) => {
  await client.models.Prompt.create({
    useragent: navigator.userAgent,
    ip_address: '',
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    prompt: prompt,
    response: response,
    openai_request: JSON.stringify(openaiRequest),
    openai_response: JSON.stringify(openaiResponse)
  })
};

const createChatGPTLog = async (agentPrompt) => {
  await client.models.ChatGPT.create({
    useragent: navigator.userAgent,
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    agent_prompt: agentPrompt,
  })
};

const formatCombinedInput = (originalInput, questions, answers) => {
  const answeredQuestions = questions
    .map((q, i) => ({ question: q.question, answer: answers[i] }))
    .filter(({ answer }) => answer && answer.trim() !== '')
    .map(({ question, answer }) => `Q: ${question}\nA: ${answer}`)
    .join('\n\n');

  return `
Original Request: ${originalInput}

Additional Context:
${answeredQuestions}
  `.trim();
};

const QuestionOverlay = ({ question, questionTitle, onClose, handleAnswerSubmit, handleFeedbackSubmit, processingAnswers }) => {
  const textareaRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={overlayRef} className="bg-white p-6 rounded-xl w-[600px] max-w-[90%]">
        <h3 className="text-xl font-semibold mb-4">{question.question}</h3>
        <textarea
          ref={textareaRef}
          className="w-full p-4 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-white"
          placeholder="Type your answer..."
          rows={4}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (questionTitle === 'ask-crayon') {
                handleFeedbackSubmit(e.target.value);
              } else {
                handleAnswerSubmit(questionTitle, e.target.value);
              }
              onClose();
            }
          }}
        />
        <div className="flex flex-col items-end gap-1 mt-4">
          <div className="flex justify-end gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 rounded-lg border hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (questionTitle === 'ask-crayon') {
                  handleFeedbackSubmit(textareaRef.current.value);
                } else {
                  handleAnswerSubmit(questionTitle, textareaRef.current.value);
                }
                onClose();
              }}
              disabled={Object.keys(processingAnswers).length > 0}
              className={`px-4 py-2 rounded-lg ${
                Object.keys(processingAnswers).length > 0
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-purple-600 hover:bg-purple-700'
              } text-white`}
            >
              Submit
            </button>
          </div>
          <span className="text-xs text-gray-500">or press Enter</span>
        </div>
      </div>
    </div>
  );
};

const CrayonInput = () => {
  const [inputText, setInputText] = useState('');
  const [tokenCount, setTokenCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [structuredPrompt, setStructuredPrompt] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [previousDisplayedText, setPreviousDisplayedText] = useState('');
  const questionRefs = useRef([]);
  const [processingAnswers, setProcessingAnswers] = useState({});
  const [promptScore, setPromptScore] = useState(0);
  const [isPostSubmission, setIsPostSubmission] = useState(false);
  const [newFullText, setNewFullText] = useState('');
  const [selectedQuestionTitle, setSelectedQuestionTitle] = useState(null);

  const navigate = useNavigate();

  const navigateToPrompt = () => {
    navigate('/prompt', { state: { inputText } });
  };

  const navigateToQuestions = async () => {
    try {
      setIsLoading(true);
      const questions = await getClarifyingQuestions(inputText);
      const parsedQuestions = JSON.parse(questions.response).response;
      const limitedQuestions = parsedQuestions.slice(0, 3);
      navigate('/questions', { 
        state: { 
          questions: limitedQuestions,
          originalInput: inputText 
        } 
      });
    } catch (error) {
      navigateToPrompt();
    } finally {
      setIsLoading(false);
    }
  };

  const handleFeedbackSubmit = async (feedbackText) => {
    setIsLoading(true);
    try {
      const promptResponse = await regeneratePromptFromFeedback(displayedText, feedbackText);
      setNewFullText(promptResponse.response);
      setIsTyping(true);
      
      setPromptScore(prevScore => Math.min(prevScore + Math.floor(Math.random() * 5), 100));

      await createPromptLog(
        displayedText,
        promptResponse.response,
        promptResponse.rawRequest,
        promptResponse.rawResponse
      );
    } catch (error) {
      console.error('Error:', error);
      navigate('/prompt', { state: { feedbackText } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (isLoading) return;

    // Reset states if this is a new prompt after initial submission
    if (isPostSubmission) {
      setStructuredPrompt(null);
      setQuestions([]);
      setAnswers({});
      setDisplayedText('');
      setPreviousDisplayedText('');
      setNewFullText('');
      setPromptScore(0);
    }

    setIsLoading(true);
    setIsPostSubmission(true);
    
    try {
      // Initial submission - use getStructuredPromptFromLLM
      const [promptResponse, questionsResponse] = await Promise.all([
        getStructuredPromptFromLLM(inputText),
        getClarifyingQuestions(inputText)
      ]);

      const parsedPrompt = JSON.parse(promptResponse.response).response;
      const parsedQuestions = JSON.parse(questionsResponse.response).response;
      
      await Promise.all([
        createPromptLog(
          inputText,
          promptResponse.response,
          promptResponse.rawRequest,
          promptResponse.rawResponse
        ),
        createPromptLog(
          inputText,
          questionsResponse.response,
          questionsResponse.rawRequest,
          questionsResponse.rawResponse
        )
      ]);

      setStructuredPrompt(parsedPrompt);
      setIsTyping(true);
      setQuestions(parsedQuestions);
      setPromptScore(Math.floor(Math.random() * 11) + 45);
      setNewFullText(promptFromJson(parsedPrompt));
      
      setInputText('');
    } catch (error) {
      console.error('Error:', error);
      navigate('/prompt', { state: { inputText } });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    setTokenCount(text.length);
  };

  const promptFromJson = (json) => {
    return `${json.persona}

${json.goal ? `${json.goal}` : ''}

Think step by step when responding:
${json.cot.map((str, index) => `${index + 1}. ${str}`).join('\n')}

Your response must adhere to the following guardrails:
${json.guardrails.map((str, index) => `${index + 1}. ${str}`).join('\n')}`;
  };

  const handleAnswerSubmit = async (questionTitle, value) => {
    const question = questions.find(q => q.title === questionTitle);
    if (!question) return;

    setProcessingAnswers(prev => ({
      ...prev,
      [questionTitle]: true
    }));

    try {
      const combinedInput = formatCombinedInput(
        inputText, 
        [question], 
        [value]
      );

      const { response, rawRequest, rawResponse } = await getStructuredPromptFromLLM(combinedInput);
      const newPrompt = JSON.parse(response).response;

      // Update the prompt and remove the question only after successful response
      setPreviousDisplayedText(displayedText);
      setStructuredPrompt(newPrompt);
      setNewFullText(promptFromJson(newPrompt));
      setIsTyping(true);

      // Remove the question and its answer
      setAnswers(prev => {
        const updated = { ...prev };
        delete updated[questionTitle];
        return updated;
      });
      
      setQuestions(prevQuestions => 
        prevQuestions.filter(q => q.title !== questionTitle)
      );

      // Increase score by 10-15 points when an answer is submitted
      setPromptScore(prevScore => {
        const increase = Math.floor(Math.random() * 6) + 10; // Random number between 10-15
        const newScore = Math.min(prevScore + increase, questions.length === 1 ? Math.floor(Math.random() * 4) + 90 : 90);
        return newScore;
      });

      await createPromptLog(
        combinedInput,
        response,
        rawRequest,
        rawResponse
      );
    } catch (error) {
      console.error('Error updating prompt:', error);
    } finally {
      // Clear processing state
      setProcessingAnswers(prev => {
        const updated = { ...prev };
        delete updated[questionTitle];
        return updated;
      });
    }
  };

  const copyToClipboardAndGoToChatGPT = () => {
    if (displayedText.length === 0) return;

    // Copy the last message to the clipboard
    createChatGPTLog(displayedText);
    navigator.clipboard.writeText(displayedText);
    alert('Prompt copied to clipboard!\nAfter clicking OK, a ChatGPT will be opened in a new tab.\nPaste your new prompt to test!');
    const newTab = window.open('https://www.chatgpt.com', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert("Failed to open tab...");
    }
  };

  useEffect(() => {
    if (newFullText && isTyping) {
      const differences = diffWordsWithSpace(previousDisplayedText, newFullText);

      let currentText = previousDisplayedText;
      let currentDiffIndex = 0;
      let position = 0;

      const typingInterval = setInterval(() => {
        if (currentDiffIndex < differences.length) {
          let meaningfulDiff = true;
          const diff = differences[currentDiffIndex];

          const consumeDiff = (diff, position, currentText) => {
            let addOrRemove = true;
            if (!diff.added && !diff.removed) {
              // Case 1: No change - move position forward by word count
              position += diff.value.length;
              addOrRemove = false;
            } else if (diff.added) {
              // Case 2: Added text - insert at current position
              currentText = currentText.slice(0, position) + diff.value + currentText.slice(position);
              position += diff.value.length;
            } else if (diff.removed) {
              // Case 3: Removed text - delete from current position
              currentText = currentText.slice(0, position) + currentText.slice(position + diff.value.length);
            }
            return {position, currentText, addOrRemove};
          }
          
          ({position, currentText, meaningfulDiff} = consumeDiff(diff, position, currentText));
          currentDiffIndex++;

          // Run twice if the diff is not meaningful.
          if (!meaningfulDiff && currentDiffIndex < differences.length) {
            ({position, currentText, meaningfulDiff} = consumeDiff(differences[currentDiffIndex], position, currentText));
            currentDiffIndex++;
          }

          setDisplayedText(currentText);
        } else {
          setIsTyping(false);
          setPreviousDisplayedText(newFullText);
          clearInterval(typingInterval);
          
          // More specific selector to target only the left panel
          const textContainer = document.querySelector('.overflow-y-auto');
          if (textContainer) {
            textContainer.scrollTop = textContainer.scrollHeight;
          }
        }
      }, 30);

      return () => clearInterval(typingInterval);
    }
  }, [newFullText, isTyping, previousDisplayedText]);

  return (
    <div className="flex flex-col h-screen mx-auto w-[95%]">
      {/* Header row */}
      <div className="px-6 pt-4 text-xl font-semibold mx-auto text-gray-700 text-left w-[95%]">
        Crayon: Making AI Easy
      </div>

      {/* Panels row */}
      <div className="flex flex-1 p-6 gap-6  mx-auto w-[95%]">
        {/* Main Canvas Panel */}
        <div className="w-2/3">
          <div className="bg-white rounded-xl shadow-md h-full flex flex-col">
            <div className="p-6 border-b border-gray-100 flex justify-between items-center">
              <h1 className="text-xl font-semibold">Prompt</h1>
            </div>
            
            <div className="flex-1 flex flex-col justify-between p-6" style={{ maxHeight: 'calc(100vh - 180px)', height: 'calc(100vh - 180px)' }}>
              <div className="flex-1 overflow-y-auto mb-6 text-left px-10">
                {newFullText && (
                  <>
                    <pre className="whitespace-pre-wrap font-mono text-sm">
                      {displayedText}
                    </pre>
                  </>
                )}
              </div>

              <div className="border border-gray-700 rounded-2xl p-4">
                <div className="flex">
                  <textarea
                    className="w-full p-4 resize-none focus:outline-none focus:ring-2 focus:ring-white"
                    placeholder={isPostSubmission ? "Create a new prompt..." : "What do you want your AI to do?"}
                    rows={1}
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (inputText && e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (!isLoading) {
                          handleSubmit();
                        }
                      }
                    }}
                    disabled={isLoading}
                  />
                  
                  <div className="flex items-center">
                    <button
                      onClick={handleSubmit}
                      className={`p-4 rounded-full font-medium flex items-center justify-center ${
                        inputText && !isLoading
                          ? 'bg-purple-600 text-white hover:bg-purple-700'
                          : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                      }`}
                    >
                      {isLoading ? <Spinner /> : (
                        isPostSubmission ? <Plus className="w-6 h-6" /> : <Send className="w-6 h-6" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Tips Panel */}
        <div className="w-1/3">
          <div className="bg-white rounded-xl shadow-md h-full p-6 flex flex-col">
            <div className="mb-8 flex items-center justify-center gap-4">
              <h2 className="text-lg font-semibold">Score</h2>
              <div className="relative w-16 h-16">
                <div className="absolute inset-0 rounded-full border-8 border-gray-200"></div>
                <div 
                  className="absolute inset-0 rounded-full border-8"
                  style={{
                    borderColor: promptScore >= 80 ? '#22c55e' : // green
                               promptScore >= 70 ? '#fbbf24' : // yellow
                               promptScore >= 50 ? '#f97316' : // orange
                               '#ef4444', // red (default)
                    clipPath: `polygon(50% 50%, 50% 0%, ${
                      Array.from({ length: 360 }, (_, i) => {
                        const angle = (i - 90) * (Math.PI / 180);
                        const x = 50 + Math.cos(angle) * 50;
                        const y = 50 + Math.sin(angle) * 50;
                        return `${x}% ${y}%`;
                      }).filter((_, i) => i <= (promptScore / 100) * 360).join(', ')
                    })`
                  }}
                ></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="text-center">
                    <span className="text-l font-bold">{promptScore}</span>
                    <span className="text-xs text-gray-500 block">/100</span>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="text-lg font-semibold mb-4">Clarifying Questions</h2>
            <div className="flex-1 overflow-y-auto mb-4" style={{ maxHeight: 'calc(100vh - 400px)', height: 'calc(100vh - 400px)' }}>
              <div className="space-y-4 question-container">
                {questions.length > 0 ? (
                  questions.map((q, index) => (
                    <div 
                      key={q.title}
                      ref={el => questionRefs.current[index] = el}
                      onClick={() => setSelectedQuestionTitle(q.title)}
                      className="space-y-2 transition-all duration-300 bg-gray-50 rounded-full p-4 border border-gray-200 cursor-pointer hover:bg-gray-100"
                    >
                      {processingAnswers[q.title] ? (
                        <div className="flex justify-center items-center">
                          <Spinner />
                        </div>
                      ) : (
                        <>
                          <p className="font-small px-2">{q.title}</p>
                          {answers[q.title] && (
                            <p className="text-sm text-gray-600 px-2">{answers[q.title]}</p>
                          )}
                        </>
                      )}
                    </div>
                  ))
                ) : ''} 
                {displayedText && (
                  <div 
                    onClick={() => setSelectedQuestionTitle('ask-crayon')}
                    className="space-y-2 transition-all duration-300 bg-gray-50 rounded-full p-4 border border-gray-200 cursor-pointer hover:bg-gray-100"
                  >
                    <p className="font-small px-2">Ask Crayon</p>
                  </div>
                )}
              </div>
            </div>
            {displayedText && (
              <div className="mt-4 flex justify-center">
                <button
                  onClick={copyToClipboardAndGoToChatGPT} 
                  disabled={isLoading || isTyping}
                  className="w-40 p-4 rounded-full font-medium bg-purple-600 text-white hover:bg-purple-700 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed">
                  <p className="font-bold">Try on ChatGPT!</p>
                </button>
              </div>
            )}

          </div>
        </div>
      </div>

      {/* Add overlay rendering */}
      {selectedQuestionTitle !== null && (
        <QuestionOverlay 
          question={
            selectedQuestionTitle === 'ask-crayon' 
              ? { question: "How else would you like the prompt to change?" }
              : questions.find(q => q.title === selectedQuestionTitle)
          }
          questionTitle={selectedQuestionTitle}
          onClose={() => setSelectedQuestionTitle(null)}
          handleAnswerSubmit={handleAnswerSubmit}
          handleFeedbackSubmit={handleFeedbackSubmit}
          processingAnswers={processingAnswers}
        />
      )}
    </div>
  );
};

export default CrayonInput;
