// openaiApi.js
import axios from 'axios';

import Ajv from 'ajv';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

/**
 * Generate a similar prompt closer to the champion and further from runnerUp.
 * @param {string} - Good example of a prompt.
 * @param {string} - Bad exmaple of a prompt.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getSimilarPrompt = async (champion, runnerUp) => {
  const systemPrompt = `
You are a language teacher that specializes in improving communication skills

Generate a prompt that incorporates elements of the preferred and avoids pitfalls of the less preferred prompt: Create a new prompt that combines the strengths of the preferred prompt while eliminating the weaknesses of the less preferred prompt.
`
  //You are a writer that seeks to optimize written content.
  //
  //Given a good version and a bad version of a prompt, create another version that is similar to the good version in structure and content but different from the bad version.
  const userPrompt = `
Good version:
${champion}

Bad version:
${runnerUp}

Optimized version:
`
  return getOpenAIResponse(systemPrompt, userPrompt, {}, false, 0.5  /* temperature */);
}


/**
 * Score synthetic examples against a prompt.
 * @param {string} - Synthetic example used as input for the prompt.
 * @param {string} - Response from an LLM.
 * @param {string} - Goal used for scoring.
 * @param {string} - Rubric used for scoring.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getEvaluationScore = async (input, response, goal, rubric) => {
  const evaluation_system = `
You are a data analyst that specializes in evaluating performance metrics

Your goal is to score the quality of a response against a rubric and goal

Think step by step:
1. A response that correctly recognizes invalid input should be scored a 5.
2. A response that does not achieve the goal should be scored a 1.
3. Review the provided rubric to understand the criteria for scoring
4. Analyze the response based on the rubric guidelines
5. Assign a score to the response based on how well it meets the criteria in the rubric
6. Provide feedback or recommendations for improvement if necessary

Your response must adhere to the following guardrails:
1. Ensure that the scoring is objective and based on the provided criteria
2. Prefer extreme scores when evaluation is justified by the rubric

Response should be structured in json as:
{
  "score": $SCORE,
  "justification": "$DESCRIPTION",
}
`

  const evaluation_user = `
Score this response and input pair aginst the provided 5 point rubric scale with
the context of the purpose statement.

Input: ${input}

Response: ${response}

Goal: ${goal}

Rubric: ${rubric}
`
  return getOpenAIResponse(evaluation_system, evaluation_user);
}


/**
 * Run synthetic examples against a prompt.
 * @param {string} - Prompt that runs a synthetic example.
 * @param {string} - Synthetic example to run against the prompt.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getSyntheticResponse = async (prompt, example) => {
  return getOpenAIResponse(prompt, example);
}


/**
 * Generate synthetic examples to comprehensively test a rubric.
 * @param {string} - Objective that is used to generate examples.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getSyntheticExamples = async (goal, rubric) => {
  const synthetic_generation_system = `
You are a software quality assurance engineer that specializes in testing.

Your goal is to come up with a comprehensive set of 5 simple test cases, each with an example input for testing.

Think step by step:
1. Identify the different functionalities or features that need to be tested
2. Determine the priority of the functionalities based on criticality or frequency of use
3. Create test cases that cover both positive and negative scenarios for each functionality
4. Review and validate the test cases to ensure they are clear, concise, and cover all necessary scenarios

Your response must adhere to the following guardrails:
1. Must have test cases that are relevant to the goal and rubric.
2. Do not include an expected response in the synthetic example.
3. Do not explain why this test_case was selected.
4. Do not test edge cases.
5. Test case must only measure quality of the response against the provided rubric and goal.
6. Test case must be a command relevant to the goal.

Response should be structured in json as:
{
  "evaluation": {
      "test_cases": [
          {
              "test_case": "$TEST_CASE",
              "synthetic_example": "$SYNTHETIC_EXAMPLE"
          },
          ...
      ],
  }
}
  `

  const synthetic_generation_user = `
  Generate 5 disparate test cases that should be accomplished given the following goal and rubric.

  Goal: ${goal}

  Rubric: ${rubric}
  `

  return getOpenAIResponse(
    synthetic_generation_system, synthetic_generation_user, {}, true /* format_json */, 0, 'gpt-4o');
}

/**
 * Construct a rubric from a goal.
 * @param {string} - String that is used to generate rubrics.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getRubricFromGoal = async (goal) => {

  const rubric_system = `
You are a quality assurance analyst that is an expert on creating test rubrics.

Given a goal, create a simple rubric scale from 1 to 5 where 1 means completely
failing the goal and 5 means outstandingly passing the goal.
Passing the rubric must help achieve the goal.

Return a rubric with a 1 sentence explanation of each value on the rubric
scale.

The rubric must be evaluated only with the response and input.

1. Rubric is a scale from 1 to 5.
2. 1 is the lowest score, 5 is the highest score.
3. A score of 5 outstandingly helps achieve the goal statement.
4. Return a simple one sentence description of each value on the rubric scale.
5. You must generate 5 separate scores and descriptions pairs.
6. The rubric must only evaluate the quality of the text response.
7. Rubric must be simple and easy to evaluate on the text response.

Response should be structured in json as:
{
  "rubric": [
    {
      "score": $SCORE
      "description": $DESCRIPTION,
  ]
}
`

  const rubric_user = `
Come up with 5 separate rubric scores and explanations
for the following goal.

${goal}
`
  return getOpenAIResponse(rubric_system, rubric_user, {}, true /* format_json */, 0, 'gpt-4o');
}

/**
 * Construct a list of guardrails from a set of objectives.
 * @param {string} - The list of objectives that is used to generate guardrails.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getGuardrailsFromObjectives = async (objectives) => {
  const system_prompt = `
You are a coach that is an expert in helping people reach their objectives.

Given a set of objectives, create a list of at most 10 guardrails that must be used to achieve the objective.

A guardrail must be a simple rule an agent can apply when generating a response.

An example of a negative guardrail is: do not repeat yourself.
An example of a positive guardrail is: check factuality of each statement.

Please structure as bullet points. Do not repeat yourself. Make sure that your guardrail is not an objective.
Your response must {guardrail}
`;
  const user_prompt = `Construct a set of at most 7 guardrails given the following objectives:

${objectives}

Your response here:
`;

  return getOpenAIResponse(system_prompt, user_prompt);
};


/**
 * Construct a high-quality agent prompt from a set of objectives.
 * @param {string} - The purpose provided by the user
 * @param {string} - The list of objectives to compile
 * @param {string} - The list of guidelines to compile
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getAgentPromptFromObjectives = async (purpose, objectives, guidelines) => {
  const system_prompt = `
You are a consultant that is an expert at writing clear communication.

Given a set of objectives and guideilnes, write a clear prompt following in the following template.

Please format with json:
{
  "response": {
    "role": "You are a {role} that is an expert in {purpose}.",
    "instruction": "Your job is to answer questions to achieve the following objectives:",
    "objectives": [{objective}, ...],
    "guardrail": "Your response must adhere to the following guidelines:",
    "guidelines": [{guideline}, ...],
  }
}
`;

  const user_prompt = `Create an agent prompt that achieves the following objectives and guidelines:

${purpose}

${objectives}

${guidelines}

Please ensure the agent's responses are aligned with these goals.
`;
  return getOpenAIResponse(system_prompt, user_prompt, {}, true /* format_json */);
};

/**
 * Break down a candidate agent purpose into a persona, goal, objectives, guardrails.
 * @param {string} agent_purpose - The agent purpose text to break down into a structured prompt.
 * @returns {Promise<Object>} - The response text parsed from json from OpenAI.
 */
export const getStructuredPromptFromLLM = async (agent_purpose) => {
  const schema = {
    type: 'object',
    properties: {
      response: {
        type: 'object',
        properties: {
          persona: { type: 'string' },
          goal: { type: 'string' },
          cot: {
            type: 'array',
            items: { type: 'string' },
            minItems: 1,
            maxItems: 4,
          },
          guardrails: {
            type: 'array',
            items: { type: 'string' },
            minItems: 1,
            maxItems: 4,
          },
        }
      }
    }
  }
  const system_prompt = `You are a consultant that is an expert at creating instructions on generating responses.

Given a agent's purpose, structure a prompt by assigning a persona, goal,
chain-of-thought, and guardrails that dictate how a response should be generated 
to achieve the stated purpose.

1. Persona is a real-world identity or profession.
2. Goal is a high-level purpose that the agent must achieve.
3. Chain-of-thought is a breakdown of how to generate a response to achieve the goal.
4. Chain-of-thought ends with generating the response.
5. Chain-of-thought should be no more than 3 steps.
6. Guardrails start with "Don't" or "Make sure to" .
7. Guardrails dictate how the response is generated.
8. Do not generate an infinite sequence of new line characters.
9. Do not include guidance about any behavior outside of the response generation.

Response should be structured as JSON:
{
  "response":
    {
      "persona": "You are a $ROLE that [specializes/is an expert in] $PERSONA_DESCRIPTION",
      "goal": "Your goal is to $GOAL",
      "cot": ["$CHAIN_OF_THOUGHT"],
      "guardrails": ["$GUARDRAIL_DESCRIPTION"]
    }
}
  `

  const user_prompt = `Come up with a structured prompt in JSON format with a persona, goal, chain-of-thought, and guardrails given the following agent purpose:

${agent_purpose}
  `
  return getOpenAIResponse(system_prompt, user_prompt, schema, true /* format_json */);
};

/**
 * Break down a candidate agent prompt into objectives.
 * @param {string} agent_prompt - The agent prompt text to break down into objectives.
 * @returns {Promise<Object>} - The response text parsed from json from OpenAI.
 */
export const getObjectivesFromLLM = async (agent_prompt) => {
  const schema = {
    type: 'object',
    properties: {
      response: {
        type: 'array',
        items: [
          {
            type: 'object',
            properties: {
              rubric_name: { type: 'string' },
              rubric_explanation: { type: 'string' }
            }
          }
        ]
      }
    }
  }
  const system_prompt = `
You are a consultant that is an expert at analyzing software agents.

Given a agent's purpose, identify 3 objectives that the agent must
accomplish to achieve the agent purpose.

1. Each objective should be no more than 5 words
2. Each objective should start with a verb
3. The agent must be the subject of each objective
4. The agent can respond to prompts, access the internet, and call other agents
5. Do not choose objectives that require human input
6. Do not choose redundant objectives
7. Write a justification for how each objective is relevant to the agent purpose


Response should be structured as json:
{
  "response": [
    {
      "rubric_name": $OBJECTIVE
      "rubric_explanation": $JUSTIFICATION
    },
    ...
  ]
}
  `

  const user_prompt = `
Come up with the most important, non-overlapping 3 objectives that must be
satisfied to achieve the following agent purpose:

${agent_prompt}
  `
  return getOpenAIResponse(system_prompt, user_prompt, schema, true /* format_json */);
};


/**
 * Send a prompt to OpenAI's API and receive a response.
 * @param {string} system_prompt - The prompt text to send to OpenAI under the 'system' role.
 * @param {string} user_prompt - The prompt text to send to OpenAI under the 'user' role.
 * @param {object} json_schema - Schema to use to validate model output.
 * @param {boolean} format_json - Whether to format response using json.
 * @param {number} temparature - The temperature to pass to OpenAI.
 * @param {string} model - The model to use (default: 'gpt-3.5-turbo').
 * @returns {Promise<{response: string, rawRequest: object, rawResponse: object}>}
 */
export const getOpenAIResponse =
  async (system_prompt, user_prompt, json_schema = {}, format_json = false, temperature = 0, model = 'gpt-4o') => {
    try {
      const request = {
        model: model,
        messages: [
          { role: 'system', content: system_prompt },
          { role: 'user', content: user_prompt }
        ],
        response_format: { type: format_json ? 'json_object' : 'text' },
        temperature: temperature,
        max_completion_tokens: 1000,
      };

      const response = await axios.post(
        OPENAI_API_URL,
        request,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_KEY}`,
          }
        }
      );

      if (format_json) {
        const ajv = new Ajv();
        const validate = ajv.compile(json_schema);
        if (!validate(response))
          throw new TypeError('Invalid JSON format returned.');
      }
      // Return the text from OpenAI's response
      return {
        response: response.data.choices[0].message.content,
        rawRequest: request,
        rawResponse: response.data
      }
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      throw new Error('Failed to retrieve response from OpenAI.');
    }
  };

/**
 * Send a prompt to OpenAI's API and receive a response.
 * @param {string} user_prompt - The prompt text to send to OpenAI under the 'user' role.
 * @param {object} json_schema - Schema to use to validate model output.
 * @param {boolean} format_json - Whether to format response using json.
 * @param {number} temparature - The temperature to pass to OpenAI.
 * @param {string} model - The model to use (default: 'gpt-3.5-turbo').
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const getOpenAIo1Response =
  async (user_prompt, json_schema = {}, format_json = false, temperature = 0, model = 'o1-preview') => {
    try {
      const response = await axios.post(
        OPENAI_API_URL,
        {
          model,
          messages: [
            { role: 'user', content: user_prompt }
          ],
          response_format: { type: format_json ? 'json_object' : 'text' }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_KEY}`,
          }
        }
      );

      if (format_json) {
        const ajv = new Ajv();
        const validate = ajv.compile(json_schema);
        if (!validate(response))
          throw new TypeError('Invalid JSON format returned.');
      }
      // Return the text from OpenAI's response
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error calling OpenAI API:', error);
      throw new Error('Failed to retrieve response from OpenAI.');
    }
  };

/**
 * Get clarifying questions for a vague task/purpose
 * @param {string} purpose - The purpose or task to get clarification on
 * @returns {Promise<Object>} - The response text parsed from json from OpenAI
 */
export const getClarifyingQuestions = async (purpose) => {
  const system_prompt = `You are an interviewee that is given an intentionally vague task.

Gather requirements by asking the interviewer questions.

Don't ask what content should be generated. That is prohibited.

1. You must ask how to measure success if success criteria is not specified.
2. Consider asking a question on refining the target audience if missing.
3. Consider asking a question on refining the persona to assume
4. For longform tasks where structure is important, consider asking how the response should be structured.
5. Don't ask the interviewer to help you solve the problem.
6. Don't ask off-topic questions
7. Don't ask yes or no questions.
8. Only ask for requirement clarifications. If requirements are clear, stop asking questions.
9. Don't ask "what specific aspects" or "what key aspects"

Create open-ended questions you should ask to clarify your problem solving approach. 

Format using json:
{
  response: [
    {
      title: '$short_question_description',
      question: 'question',
    },
  ]
}`;

  const user_prompt = `prompt: '${purpose}'`;

  return getOpenAIResponse(
    system_prompt,
    user_prompt,
    {}, // schema
    true, // format_json
    0, // temperature
    'gpt-4o' // model
  );
};

/**
 * Regenerate a prompt based on feedback.
 * @param {string} generatedPrompt - The initial generated prompt.
 * @param {string} feedback - Feedback to improve the prompt.
 * @returns {Promise<string>} - The response text from OpenAI.
 */
export const regeneratePromptFromFeedback = async (generatedPrompt, feedback) => {
  const system_prompt = `
You are a language model tasked with improving prompts based on feedback.

Your goal is to generate a new version of the prompt that incorporates the feedback provided.

Make sure to respect the structure and strengths of the initial prompt unless explicitly asked to change them.

Respond with the ONLY the improved prompt. Do not add any other text.
`;

  const user_prompt = `generated prompt: ${generatedPrompt} feedback: ${feedback}`;

  return getOpenAIResponse(
    system_prompt,
    user_prompt,
    {}, // No specific schema
    false, // Not formatting as JSON
    0.7, // Temperature for creativity
    'gpt-4o' // model
  );
};
